/* EventsPage.css */
.events-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.events-container h1 {
  font-size: 2.5em;
  margin-bottom: 15px;
  text-align: center;
}

.events-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
}

.event-list {
  margin-top: 20px;
}

.event {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  color: #000;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.event-image {
  width: 100%;
  max-width: 120px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.event h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.event p {
  font-size: 1em;
  margin: 5px 0;
}

.event strong {
  font-weight: bold;
}
