#courses {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .course {
    margin-bottom: 10px;
  }
  
  .course h2 {
    font-size: 24px;
  }
  
  .course p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .course ul {
    padding: 0;
  }
  
  .course ul li {
    margin-bottom: 10px;
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .course ul li strong {
    font-weight: bold;
  }
  
  .enrollment-form {
    background-color: #22354b;
    padding: 20px;
    border-radius: 8px;
    color: white;
  }
  
  .enrollment-form p {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .enrollment-form ul {
    padding: 0;
  }
  
  .enrollment-form ul li {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  
  .enrollment-form textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }

  
  #courses h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  #courses ul {
    list-style-type: disc;
    padding: 0;
  }
  
  #courses li {
    padding:0px 6px;
    border-radius: 8px;
    margin-left: 20px;
  }
  
  #courses li h2 {
    font-size: 20px;
  }
  
  #courses li p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  #courses button{
    background-color: #2262ad;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }
  