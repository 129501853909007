/* BlogNewsPage.css */
.blog-news-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.blog-news-container h1 {
  font-size: 2.5em;
  margin-bottom: 15px;
  text-align: center;
}

.blog-news-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
}

.blog-posts {
  margin-top: 20px;
}

.blog-post {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-post-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.blog-post h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.blog-post p {
  font-size: 1em;
  margin: 5px 0;
}

.blog-post strong {
  font-weight: bold;
}
